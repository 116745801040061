import { DashboardPlanningInfo } from '@/support/Info';
import { Component, Watch } from 'vue-property-decorator';
import { AxiosError, AxiosResponse } from 'axios';
import { Organization } from '@/models/Organization';
import ErrorHandler from '@/support/ErrorHandler';
import { Setting } from '@/models/Setting';
import { DateFormatter } from '@/support/DateFormatter';
import { DateTime } from 'luxon';
import { StatisticsColumn } from '@/components/week-statistics-view/WeekStatisticsView';
import { Rpc } from '@/models/Rpc';
import { getKpiClass } from '@/support/Dashboard';
import AbstractMoreInfoDialog from '@/components/dialog/more-info-dialog/AbstractMoreInfoDialog';
import { MoreInfo } from '@/components/dialog/more-info-dialog/MoreInfoDialog';

@Component<Dashboard>({})
export default class Dashboard extends AbstractMoreInfoDialog {
  public $pageTitle = 'Immaterieel';

  protected isLoading = true;

  protected organizations: Organization[] = [];

  protected organization: Organization | null = null;

  protected settings: Setting[] = [];

  protected planningStatistics: PlanningStatistic[] = [];

  protected cancelledPlanningStatistics: PlanningStatistic[] = [];

  protected moreInfo: MoreInfo[] = DashboardPlanningInfo;

  protected date: DateFormatter = new DateFormatter();

  // columns
  protected columns: StatisticsColumn[] = [
    {
      key: 'name',
      name: 'Type',
      class: 'xs4 md2 lg4',
    },
    {
      key: 'week',
      name: 'Ma',
      class: 'xs1 text-right',
      transform: (item: any) => `<span class="primary--text">${item.total}</span>`,
    },
    {
      key: 'week',
      name: 'Di',
      class: 'xs1 text-right',
      transform: (item: any) => `<span class="primary--text">${item.total}</span>`,
    },
    {
      key: 'week',
      name: 'Wo',
      class: 'xs1 text-right',
      transform: (item: any) => `<span class="primary--text">${item.total}</span>`,
    },
    {
      key: 'week',
      name: 'Do',
      class: 'xs1 text-right',
      transform: (item: any) => `<span class="primary--text">${item.total}</span>`,
    },
    {
      key: 'week',
      name: 'Vr',
      class: 'xs1 text-right',
      transform: (item: any) => `<span class="primary--text">${item.total}</span>`,
    },
    {
      key: 'week',
      name: 'Za',
      class: 'xs1 text-right',
      transform: (item: any) => `<span class="primary--text">${item.total}</span>`,
    },
    {
      key: 'week',
      name: 'Zo',
      class: 'xs1 text-right',
      transform: (item: any) => `<span class="primary--text">${item.total}</span>`,
    },
    {
      key: 'row_total',
      name: 'Totaal',
      class: 'xs1 text-right',
    },
  ];

  public mounted() {
    this.date.selectedDate = DateTime.local().toFormat('yyyy-LL-dd');
    this.emitBreadcrumb();
    this.initialize();
  }

  protected async initialize() {
    if (this.$store.state.isServiceOrganization) {
      this.isLoading = true;
      await this.fetchStatisticsDetail();
      await this.fetchCancelledStatisticsDetail();
      this.isLoading = false;
    }
  }

  protected async fetchStatisticsDetail() {
    const payload = {
      signature: 'management-reports:ims-planning-detail',
      body: {
        date: this.date.selectedDate,
      },
    };

    await new Rpc()
      .rpcPost(payload, false)
      .then((response: AxiosResponse) => {
        this.planningStatistics = response.data as PlanningStatistic[];
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async fetchCancelledStatisticsDetail() {
    const payload = {
      signature: 'management-reports:ims-cancelled-planning-detail',
      body: {
        date: this.date.selectedDate,
      },
    };

    await new Rpc()
      .rpcPost(payload, false)
      .then((response: AxiosResponse) => {
        this.cancelledPlanningStatistics = response.data as PlanningStatistic[];
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getKpiClass(amount: number, warningAt = 5, alertAt = 10, inverted = false) {
    return getKpiClass(amount, warningAt, alertAt, inverted);
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Imaterieel' },
        ],
      });
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}

export interface PlanningStatistic {
  id: string;
  name: string;
  totals: PlanningStatisticsTotal;
  week: PlanningStatisticsTotal[];
}

interface PlanningStatisticsTotal {
  date?: string;
  canceled: number;
  planned: number;
  total: number;
  completed: number;
}
